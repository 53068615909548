import { homeDeliverySlugsB2C, overviewTypes } from '@boss/constants/b2b-b2c';
import type { Theme } from '@boss/types/b2b-b2c';

import { mergeOptions } from '../default';

const sectionBgColorMap = {
  pink: 'bg-pink-light before:bg-pink-light after:bg-pink-light',
  green: 'bg-green-soft before:bg-green-soft after:bg-green-soft',
  blue: 'bg-blue-soft before:bg-blue-soft after:bg-blue-soft',
  grey: 'bg-grey-light before:bg-grey-light after:bg-grey-light',
  beige: 'bg-beige-light before:bg-beige-light after:bg-beige-light',
};

const options = mergeOptions({
  youtubeLink: 'https://www.youtube.com/@colorapaint',
  getSectionBgColor: (theme: Theme) => (theme ? sectionBgColorMap[theme] : null),
  sectionQuoteProps: { focus: 'quote', className: 'gap-10 items-center md:min-h-50', variant: 'primary' },
  sectionQuoteQuoteProps: { className: 'my-auto' },
  sectionQuoteImageProps: { round: 'all', square: 'bl', className: 'md:absolute object-contain' },
  productCardVariant: 'primary',
  storeFinderVariant: 'primary',
  storeCardVariant: 'primary',
  orderCardVariant: 'primary',
  storeDetailTheme: 'pink',
  priceConfig: {
    visible: true,
  },
  accountPages: ['DASHBOARD', 'INFO', 'QUOTATION', 'ORDERS', 'ADVICE', 'PROJECTS', 'PAINTGUIDES', 'CONTACT'],
  accountNavigation: {
    navigationType: 'primary',
    mainNav: ['DASHBOARD', 'INFO', 'QUOTATION', 'ORDERS', 'ADVICE'],
    atelierNav: ['PROJECTS', 'PAINTGUIDES'],
    personalNav: ['CONTACT', 'LOGOUT'],
  },
  accountPageConfig: {
    showBreadcrumb: false,
    customHeader: true,
    variant: 'primary',
    listPageTypeToShow: 'PROJECTS',
    contactPageTypeToShow: 'CONTACT',
    wishlistType: 'product',
  },
  accountDashboardVariant: 'primary',
  productLabelVisibilityConfig: {
    'bossflash-action': 'hide',
    personal: 'hide',
    pallet: 'hide',
    campaign: 'show',
  },
  isB2b: false,
  pageOptions: {
    mainCategoryOverview: {
      breadcrumbVisibility: false,
    },
  },
  categoryPageConfig: {
    showPaintGuideLink: true,
  },
  homeDeliverySlugs: homeDeliverySlugsB2C,
  accountContactInformationType: 'B2C contact information',
  seoConfig: { titleSuffixContent: 'colora.be', titleSuffixContentNl: 'colora.nl' },
  storeConfig: {
    sortPriorityStoreType: 'Colora',
    excludeNamePrefix: 'BOSS paints',
    lowerCasePrefix: 'colora',
    shopsOnly: true,
  },
  surfaceCalculationConfig: {
    showTechnique: false,
  },
  overviewPageTypes: [overviewTypes.PAINTING_TIPS, overviewTypes.COLOR_TIPS, overviewTypes.INSIDE_VIEWER],
  eventType: 'WORKSHOP',
});

export default options;
